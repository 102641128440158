import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >

<path d="M4287 7684 c-4 -4 -7 -40 -7 -81 l0 -73 58 0 c44 0 63 -5 75 -18 22
-24 21 -45 -2 -66 -15 -14 -37 -16 -120 -15 -101 1 -131 -7 -131 -37 0 -7 7
-20 16 -28 13 -14 55 -16 300 -16 l284 0 11 -24 c8 -17 8 -29 0 -45 -12 -21
-14 -21 -390 -21 -333 0 -380 -2 -392 -16 -9 -11 -10 -20 -2 -32 9 -15 43 -18
295 -22 297 -5 318 -8 318 -50 0 -9 -8 -24 -18 -33 -15 -14 -49 -17 -203 -19
l-184 -3 -3 -24 c-2 -13 0 -27 5 -32 5 -5 149 -9 322 -9 172 -1 323 -5 337
-10 31 -12 34 -64 5 -80 -13 -6 -188 -10 -500 -10 -475 0 -480 0 -486 -20 -15
-48 -2 -50 296 -50 266 0 280 -1 299 -20 24 -24 24 -30 6 -56 -12 -16 -29 -20
-105 -22 l-91 -4 0 -34 0 -34 235 0 235 0 15 -24 c19 -29 6 -66 -23 -66 -9 -1
-106 0 -215 0 -290 1 -425 -8 -437 -31 -7 -13 -6 -22 6 -33 12 -13 40 -16 139
-16 113 0 125 -2 135 -19 26 -49 -2 -71 -90 -71 -49 0 -73 -4 -84 -16 -20 -20
-20 -27 2 -47 15 -14 40 -17 126 -17 104 0 107 -1 118 -25 9 -21 8 -28 -7 -45
-15 -16 -31 -20 -87 -20 l-68 0 0 -565 0 -565 710 0 c450 0 747 4 812 11 263
28 492 129 616 271 47 52 100 145 119 205 16 51 18 53 52 53 72 0 101 22 85
63 -4 12 -28 16 -121 19 -96 2 -117 6 -128 21 -14 19 -10 53 9 65 6 4 81 9
166 12 139 4 155 7 158 23 9 46 3 47 -389 47 l-369 0 -11 24 c-8 17 -8 29 0
45 12 21 14 21 493 23 445 3 482 4 492 21 8 12 8 22 0 35 -10 15 -42 17 -355
22 -189 3 -350 9 -356 13 -16 10 -17 49 -1 65 8 8 58 12 158 12 160 0 185 7
178 51 -3 23 -6 24 -88 27 -77 3 -88 5 -115 31 -60 56 -206 139 -310 175 -16
6 -9 13 41 40 157 83 257 201 305 356 19 62 23 96 23 195 -1 103 -5 131 -28
194 -35 99 -75 159 -157 235 -106 98 -255 165 -439 197 -74 13 -1530 25 -1543
13z m1228 -574 c136 -20 211 -75 235 -172 24 -94 -24 -194 -112 -236 -69 -32
-171 -42 -427 -42 l-241 0 0 230 0 230 238 0 c130 0 268 -5 307 -10z m173
-967 c116 -39 172 -107 172 -211 0 -93 -38 -155 -120 -197 -79 -39 -135 -45
-460 -45 l-310 0 0 233 c0 129 2 236 5 239 3 2 150 4 327 3 299 -1 328 -3 386
-22z"/>
<path d="M3901 7416 c-26 -32 13 -76 53 -60 12 5 16 16 14 38 -2 25 -8 32 -29
34 -14 2 -31 -4 -38 -12z"/>
<path d="M4061 7072 c-13 -25 5 -52 34 -52 29 0 39 14 31 46 -8 29 -50 34 -65
6z"/>
<path d="M3664 6906 c-12 -31 0 -51 31 -51 27 0 30 3 30 30 0 25 -4 30 -28 33
-17 2 -29 -2 -33 -12z"/>
<path d="M4052 6408 c-18 -18 -14 -46 7 -58 11 -5 22 -10 25 -10 8 0 36 31 36
40 0 13 -30 40 -44 40 -6 0 -17 -5 -24 -12z"/>
<path d="M7081 6061 c-20 -36 13 -70 53 -55 12 5 16 16 14 38 -2 25 -8 32 -30
34 -18 2 -30 -3 -37 -17z"/>
<path d="M6795 5737 c-27 -20 -16 -61 17 -65 28 -3 55 28 43 48 -13 21 -42 29
-60 17z"/>
<path d="M4620 4610 c0 -60 -2 -110 -5 -110 -3 0 -16 9 -28 21 -32 30 -61 39
-125 39 -126 0 -202 -93 -202 -248 0 -64 5 -87 26 -130 62 -124 210 -160 301
-73 29 28 33 27 33 -9 l0 -30 80 0 80 0 0 325 0 325 -80 0 -80 0 0 -110z m-27
-221 c40 -49 34 -123 -14 -163 -43 -37 -83 -34 -125 8 -29 29 -34 41 -34 79 0
48 26 93 63 106 31 11 89 -5 110 -30z"/>
<path d="M7210 4395 l0 -325 80 0 80 0 0 325 0 325 -80 0 -80 0 0 -325z"/>
<path d="M2250 4384 l0 -316 203 4 c185 3 206 5 248 26 71 35 92 63 97 131 6
82 -28 137 -103 165 -5 2 7 19 28 37 69 60 69 161 0 218 -50 42 -107 51 -303
51 l-170 0 0 -316z m340 156 c11 -11 20 -27 20 -35 0 -8 -9 -24 -20 -35 -17
-17 -33 -20 -100 -20 l-80 0 0 55 0 55 80 0 c67 0 83 -3 100 -20z m10 -223
c17 -9 26 -22 28 -44 5 -49 -24 -63 -130 -63 l-88 0 0 53 c0 30 3 57 7 60 11
12 157 7 183 -6z"/>
<path d="M6610 4386 l0 -317 83 3 82 3 3 88 3 87 53 0 c172 0 274 64 297 187
19 97 -24 184 -114 230 -50 26 -61 27 -229 31 l-178 4 0 -316z m318 153 c47
-30 44 -114 -4 -140 -14 -7 -52 -14 -83 -14 l-56 0 -3 89 -3 88 64 -5 c35 -3
74 -11 85 -18z"/>
<path d="M3915 4668 c-2 -7 -6 -35 -7 -63 -2 -41 -7 -51 -23 -54 -28 -5 -30
-12 -27 -75 4 -52 6 -56 28 -56 24 0 24 -1 24 -107 0 -119 12 -177 42 -210 26
-28 105 -47 160 -38 73 12 78 18 78 90 l0 64 -30 -11 c-24 -8 -35 -7 -55 6
-24 16 -25 21 -25 111 l0 95 55 0 55 0 0 65 0 65 -39 0 c-66 0 -71 5 -71 71
l0 59 -80 0 c-56 0 -82 -4 -85 -12z"/>
<path d="M5448 4618 c-3 -60 -4 -63 -30 -66 -27 -3 -28 -5 -28 -68 l0 -64 30
0 30 0 0 -127 c0 -141 10 -173 67 -210 32 -21 140 -24 186 -5 25 11 27 15 27
76 l0 64 -35 -9 c-23 -7 -41 -6 -55 1 -18 10 -20 21 -20 110 l0 100 55 0 55 0
0 68 0 67 -52 0 -53 0 -5 60 -5 60 -82 3 -82 3 -3 -63z"/>
<path d="M2995 4541 c-74 -33 -124 -111 -132 -202 -8 -85 11 -143 66 -200 95
-98 265 -105 364 -14 15 14 27 29 26 33 0 4 -17 21 -38 39 l-37 32 -52 -26
c-48 -23 -57 -25 -92 -14 -39 12 -84 56 -74 72 3 5 78 9 166 9 l161 0 -7 57
c-12 106 -58 180 -131 214 -54 25 -166 25 -220 0z m150 -107 c19 -8 48 -61 40
-74 -8 -14 -165 -13 -165 0 0 18 29 68 42 73 19 8 64 8 83 1z"/>
<path d="M3484 4536 c-70 -43 -95 -136 -56 -201 22 -36 46 -50 149 -84 63 -21
78 -30 78 -46 0 -33 -81 -33 -152 0 -31 14 -57 24 -58 23 -1 -2 -14 -24 -29
-50 l-27 -47 23 -15 c81 -53 240 -68 312 -30 61 32 81 65 81 136 0 56 -2 62
-36 93 -24 21 -63 41 -110 56 -82 26 -99 35 -99 55 0 25 49 27 116 5 35 -11
66 -21 69 -21 3 0 16 23 30 50 l26 51 -48 22 c-35 16 -72 22 -138 25 -82 3
-94 0 -131 -22z"/>
<path d="M4995 4556 c-5 -2 -29 -9 -53 -16 -31 -10 -41 -18 -38 -29 3 -9 10
-36 16 -59 9 -35 15 -42 28 -37 39 15 123 23 153 15 42 -12 59 -28 59 -55 0
-23 -2 -23 -75 -18 -142 11 -215 -32 -223 -130 -7 -86 28 -136 111 -158 52
-14 126 -2 158 27 21 19 26 18 32 -6 5 -19 12 -21 84 -18 l78 3 0 175 c0 166
-1 177 -24 216 -37 65 -87 88 -201 91 -52 1 -99 1 -105 -1z m163 -288 c15 -19
3 -66 -21 -83 -26 -18 -88 -20 -105 -3 -17 17 -15 58 5 80 19 21 104 25 121 6z"/>
<path d="M5887 4545 c-27 -7 -51 -16 -54 -18 -3 -3 3 -31 12 -62 13 -46 19
-55 33 -50 9 4 40 10 69 15 88 15 143 -12 143 -69 0 -17 -4 -19 -32 -10 -87
25 -193 3 -238 -48 -57 -64 -39 -175 34 -219 46 -27 140 -26 189 4 46 27 43
27 50 2 5 -19 12 -21 84 -18 l78 3 3 153 c1 91 -2 170 -9 195 -14 52 -72 111
-124 126 -53 15 -178 12 -238 -4z m201 -300 c4 -38 -14 -63 -54 -74 -43 -12
-78 6 -82 43 -6 46 23 67 82 64 49 -3 51 -4 54 -33z"/>
<path d="M7470 4380 c0 -96 5 -192 10 -212 30 -105 180 -141 265 -63 33 32 35
31 35 -5 l0 -31 83 3 82 3 3 237 2 237 -85 3 -85 3 0 -146 c0 -133 -2 -149
-21 -173 -37 -47 -118 -23 -120 37 0 12 -1 80 -2 150 l-2 129 -83 1 -82 2 0
-175z"/>
<path d="M8134 4541 c-150 -92 -146 -323 8 -402 71 -36 197 -17 230 34 13 20
21 -1 14 -37 -4 -21 -19 -45 -37 -60 -26 -22 -40 -26 -95 -26 -39 0 -84 8
-114 19 l-51 19 -25 -49 c-13 -27 -22 -54 -19 -58 22 -37 249 -64 345 -41 66
16 133 76 149 135 6 23 11 131 11 260 l0 220 -80 -3 c-70 -3 -80 -6 -80 -22 0
-26 -15 -33 -31 -14 -39 47 -166 61 -225 25z m206 -118 c86 -50 49 -183 -51
-183 -28 0 -77 27 -89 49 -13 26 -13 77 1 102 11 22 62 48 92 49 11 0 32 -7
47 -17z"/>
<path d="M6156 3812 c-2 -4 -7 -25 -11 -47 -11 -65 -12 -68 -35 -45 -38 38
-124 18 -148 -35 -19 -41 -15 -102 8 -125 22 -22 78 -27 111 -10 15 8 19 7 19
-4 0 -22 39 -7 45 18 19 83 45 222 45 238 0 18 -24 25 -34 10z m-55 -123 c16
-16 20 -28 15 -52 -10 -47 -34 -68 -75 -65 -29 2 -38 8 -45 31 -13 36 2 78 34
94 35 18 47 16 71 -8z"/>
<path d="M6660 3817 c0 -1 -11 -58 -25 -126 -28 -136 -25 -151 26 -151 19 0
29 5 29 15 0 8 -7 15 -15 15 -20 0 -19 28 5 145 11 53 20 99 20 101 0 2 -9 4
-20 4 -11 0 -20 -1 -20 -3z"/>
<path d="M3047 3794 c-15 -8 -32 -27 -38 -41 -13 -36 9 -67 71 -98 52 -26 64
-51 35 -75 -19 -16 -81 -7 -106 16 -15 14 -21 14 -34 4 -20 -17 -11 -29 42
-52 57 -25 114 -17 143 19 39 50 20 92 -61 128 -33 16 -40 23 -37 44 2 21 9
27 36 29 19 2 44 -4 59 -13 21 -14 27 -15 34 -4 14 23 11 29 -26 45 -44 18
-82 17 -118 -2z"/>
<path d="M3284 3765 c-3 -14 -12 -25 -20 -25 -16 0 -19 -27 -3 -32 7 -2 7 -23
-2 -70 -16 -85 -10 -98 41 -98 33 0 40 3 40 20 0 17 -5 19 -25 14 -29 -7 -30
-2 -14 79 11 52 14 56 42 59 42 4 48 28 8 28 -28 0 -31 2 -25 25 5 21 3 25
-15 25 -14 0 -23 -8 -27 -25z"/>
<path d="M5580 3770 c0 -10 -9 -24 -20 -30 -24 -13 -26 -30 -5 -30 11 0 14 -7
10 -22 -31 -131 -23 -153 53 -149 14 1 22 7 22 20 0 14 -6 17 -26 13 l-27 -5
8 58 c9 79 13 85 45 85 16 0 30 7 34 15 4 11 -2 15 -24 15 -25 0 -30 4 -30 25
0 18 -5 25 -20 25 -13 0 -20 -7 -20 -20z"/>
<path d="M3453 3720 c-55 -33 -64 -143 -13 -170 26 -14 83 -13 99 3 11 10 14
10 18 0 4 -11 43 -20 43 -10 0 8 31 165 36 180 4 12 0 17 -16 17 -13 0 -24 -8
-27 -19 -5 -19 -5 -19 -24 0 -25 25 -75 24 -116 -1z m108 -31 c24 -24 21 -70
-8 -101 -25 -27 -84 -21 -99 11 -22 44 20 111 68 111 10 0 27 -9 39 -21z"/>
<path d="M3701 3653 c11 -49 21 -93 23 -99 7 -20 -24 -54 -48 -54 -25 0 -51
-22 -39 -33 11 -11 67 -7 83 6 16 12 160 247 160 260 0 4 -8 7 -18 7 -11 0
-31 -25 -57 -75 -22 -41 -43 -75 -47 -75 -4 0 -12 34 -19 75 -12 67 -15 75
-35 75 l-22 0 19 -87z"/>
<path d="M4065 3728 c-59 -32 -76 -125 -31 -167 27 -26 89 -31 126 -11 23 12
22 38 -1 31 -75 -22 -112 -2 -107 57 2 25 11 41 31 56 24 18 31 19 59 8 40
-15 64 1 34 24 -23 17 -83 18 -111 2z"/>
<path d="M4284 3706 c-27 -27 -34 -42 -34 -74 0 -59 30 -92 85 -92 56 0 87 14
108 50 43 72 -1 150 -85 150 -32 0 -47 -7 -74 -34z m116 -16 c23 -23 25 -51 9
-83 -15 -28 -28 -37 -58 -37 -61 0 -84 80 -35 119 33 26 58 27 84 1z"/>
<path d="M4534 3703 c-3 -21 -12 -65 -19 -98 -15 -67 -12 -80 14 -65 12 6 21
26 26 56 12 84 39 115 92 106 29 -4 36 -30 24 -83 -16 -67 -14 -91 6 -87 19 4
40 74 42 140 2 57 -56 86 -112 57 -16 -9 -22 -9 -25 0 -2 6 -12 11 -22 11 -14
0 -21 -10 -26 -37z"/>
<path d="M4800 3683 c-7 -32 -16 -76 -20 -98 -6 -27 -5 -42 2 -46 22 -14 36 3
42 53 11 81 30 108 77 108 42 0 53 -21 40 -80 -16 -73 -14 -92 6 -88 12 2 22
20 29 53 20 82 17 122 -8 139 -27 19 -79 21 -97 4 -11 -10 -14 -10 -18 0 -3 6
-13 12 -23 12 -15 0 -21 -12 -30 -57z"/>
<path d="M5098 3724 c-30 -16 -58 -61 -58 -94 0 -35 27 -77 60 -90 39 -16 105
-3 105 20 0 8 -7 13 -15 11 -48 -13 -66 -11 -87 9 -13 11 -23 25 -23 30 0 6
36 10 80 10 87 0 98 7 86 55 -15 59 -87 83 -148 49z m96 -30 c32 -32 20 -44
-44 -44 -33 0 -60 3 -60 7 0 4 10 17 22 30 25 27 60 30 82 7z"/>
<path d="M5329 3712 c-24 -21 -32 -39 -36 -74 -5 -40 -2 -50 20 -72 21 -21 36
-26 74 -26 55 1 94 23 75 42 -9 9 -15 9 -24 0 -7 -7 -27 -12 -45 -12 -65 0
-83 90 -26 127 21 14 30 15 60 4 27 -10 38 -10 46 -1 19 18 -20 40 -70 40 -33
0 -50 -7 -74 -28z"/>
<path d="M5734 3706 c-43 -43 -47 -100 -10 -143 21 -24 32 -28 75 -28 52 0 83
17 65 35 -6 6 -20 6 -41 -2 -26 -10 -35 -9 -57 5 -14 10 -26 24 -26 32 0 12
16 15 80 15 l80 0 0 39 c0 51 -35 81 -92 81 -32 0 -47 -7 -74 -34z m120 -18
c22 -31 8 -40 -60 -36 l-59 3 27 28 c33 33 71 35 92 5z"/>
<path d="M6395 3728 c-38 -20 -55 -51 -55 -100 0 -61 24 -88 78 -88 20 0 43 4
51 9 9 6 19 4 29 -6 21 -21 29 -8 47 82 9 44 18 88 21 98 4 13 -1 17 -20 17
-20 0 -26 -5 -26 -22 l0 -21 -23 21 c-25 24 -70 28 -102 10z m90 -33 c25 -20
25 -77 0 -105 -22 -24 -77 -27 -95 -5 -22 26 -14 77 15 103 30 25 52 27 80 7z"/>
<path d="M6750 3733 c0 -16 32 -188 36 -194 13 -21 48 10 81 72 20 38 40 69
44 69 3 0 10 -31 13 -70 7 -65 9 -70 31 -70 25 0 31 9 100 133 34 62 35 67 17
67 -15 0 -32 -21 -64 -81 l-43 -81 -8 48 c-18 117 -17 115 -39 112 -14 -2 -33
-27 -62 -80 l-41 -78 -7 33 c-4 17 -10 53 -13 80 -5 38 -10 47 -26 47 -10 0
-19 -3 -19 -7z"/>
<path d="M7152 3724 c-52 -36 -66 -121 -26 -165 19 -21 98 -27 109 -9 9 15 25
12 25 -4 0 -8 8 -13 17 -12 13 2 22 23 35 87 22 110 22 119 -2 119 -14 0 -20
-7 -20 -22 l0 -21 -23 21 c-28 26 -82 29 -115 6z m88 -24 c34 -18 42 -46 26
-84 -23 -55 -88 -63 -115 -15 -12 22 -12 32 -2 56 11 27 40 50 64 52 5 1 17
-4 27 -9z"/>
<path d="M7406 3638 c21 -93 22 -104 7 -120 -8 -9 -27 -18 -42 -20 -30 -3 -36
-31 -8 -36 47 -9 64 7 145 143 73 122 79 135 59 135 -17 0 -31 -16 -62 -75
-22 -41 -43 -75 -47 -75 -3 0 -12 34 -19 75 -10 67 -14 75 -34 75 l-22 0 23
-102z"/>
<path d="M7632 3724 c-42 -29 -26 -71 41 -104 16 -8 26 -20 25 -29 -4 -22 -54
-26 -83 -7 -21 14 -24 14 -29 0 -9 -23 -8 -24 21 -35 43 -16 91 -14 113 6 38
35 20 80 -43 104 -19 7 -27 17 -25 29 3 17 29 20 82 10 29 -5 30 17 1 30 -36
16 -76 15 -103 -4z"/>
<path d="M7801 3566 c-9 -10 -8 -16 4 -26 19 -16 41 1 32 24 -8 20 -21 20 -36
2z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
